// Cláses exclusivas para la página del preHome

main.inicio {
    background: url("/images/backhome.jpg");
    background-size: cover;
    section{
        display: flex;
        color: #3a3a41;
        flex-wrap: wrap;
        .left{
            background-color: rgba(255, 255, 255, 0.88);
            height: fit-content;
            padding: 3px 33px 9px;
            text-align: center;
            width: 100%;
            img{
                width: 60%;
                height: 39%;
                margin: 33px 12px 69px;
                max-width: 200px;
            }
            h1{
                font-size: 33px;
                font-weight: 400;
                margin-bottom: 21px;
                margin-top: 15px;
                span{
                    font-weight: 700;
                }
            }
            p{
                font-size: 17px;
                font-weight: 300;
                line-height: 1.18;
                text-align: left;
                span{
                    font-weight: bold;
                }
            }
        }
        .bottom{
            background-color: white;
            width: 100%;
            p{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 15px;
                text-align: center;
                line-height: 1.17;
                padding: 33px 3px;
                span{
                    font-size: 21px;
                    font-weight: 700;
                    display: none;
                }
                &.buttons{
                    bottom: 0;
                    display:flex;
                    height: 1.7em;
                    margin: 15px 0;
                    position: fixed;
                    width: 100%;
                }
            }
            a{
                background-color: #3dabe3;
                border: 1px solid;
                color: white;
                display: inline-block;
                font-size: 0.88em;
                height: 69px;
                margin: 0 0.2em;
                border-radius: 10px 10px 0 0;
                padding-right: 12px;
                padding-left: 12px;
                padding-top: .7em;
                width: 50%;
                vertical-align: middle;
                line-height: 1.25em;
                &:hover{
                    border-bottom: 1px solid;
                }
                &.buy{
                    background-color: #146994;
                }
            }
        }
        .right{
            background-color: rgba(36, 35, 39, 0.45);
            padding: 27px 15px 120px;
            text-align: center;
            width: 100%;
            p{
                font-size: 20px;
                font-weight: 300;
                margin-bottom: 45px;
                span{
                    font-size: 21px;
                    font-weight: 700;
                    display: none;
                }
                &.buttons{
                    bottom: 0;
                    display:flex;
                    height: 1.7em;
                    margin: 15px -15px;
                    position: fixed;
                    width: 100%;
                }
            }
            a{
                background-color: #3dabe3;
                border: 1px solid;
                color: white;
                display: inline-block;
                font-size: 0.88em;
                height: 69px;
                margin: 0 0.2em;
                border-radius: 10px 10px 0 0;
                padding-right: 12px;
                padding-left: 12px;
                padding-top: .7em;
                width: 50%;
                vertical-align: middle;
                line-height: 1.25em;
                &:hover{
                    border-bottom: 1px solid;
                }
                &.buy{
                    background-color: #146994;
                }
            }
        }
    }
    .mobile-offset{
    }
    @media (min-width: 600px){
        section .left {
            padding: 3px 99px 39px;
            img {
                width: 69%;
                max-width: 177px;
            }
        }
    }
    @media (min-width: 992px){
        section{
            flex-flow: column;
        }
        section .left {
            padding: 33px 69px 3% 114px;
            width: 44.5%;
            img {
                width: 69%;
                margin-top: 33px;
                position: relative;
                float: left;
                margin-right: 50%;
            }
            p{
                font-size: 17px;
                text-align: left;
            }
            h1{
                font-size: 40px;
                text-align: left;
                font-weight: bold;
                width: 94%;
            }
        }
        section .bottom{
            padding: 33px 60px 9% 114px;
            height: fit-content;
            width: 44.5%;
            p{
                padding: 33px 3px 0;
                text-align: left;
            }
            p.buttons{
                position: relative;
                padding: 0;
                justify-content: space-between;
            }
            a{
                background: {
                    clip : border-box;
                    color: #3dabe3;
                }
                border: {
                    color: transparent;
                    radius: 24px;
                    style: solid;
                    width: 0;
                }
                color: white;
                font-size: 13px;
                height: 47px;
                margin-top: 21px;
                overflow: hidden;
                padding-top: 16px;
                position: relative;
                text-align: center;
                transition: background-color 150ms,
                box-shadow 150ms,
                font-size 150ms,
                transform 150ms;
                text-transform: uppercase;
                width: 47%;
                &:hover, &:focus {
                    background-color: darken(#3dabe3, 10%);
                    border-radius: 24px;
                    box-shadow: 0 2px 5px rgba(0,0,0,0.6);
                    transform: scale(1.1);
                }
            }
        }
        section .right{
            padding: 0;
            width: 50%;
            p{
                font-size: 22px;
                text-align: left;
            }
        }
    }
    @media (min-width: 1441px){
        section .right{
            padding: 39% 69px 202px;
        }
    }
}
